@import "../../../assets/sass/_variables";

.footer {
  height: 3%;
  border-top: 1px solid $border-color;
  bottom: 0px;
  position: fixed;
  width: 100%;
  // background-color: $color-primary;
  // color: #000000;
  // opacity: 0.5;
}