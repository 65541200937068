@import "../../../assets/sass/_variables";

.side-bar {
  // background-color: $color-secondary;
  overflow: scroll;

  .menu-item {
    // color: $color-primary;
    border-bottom: 1px solid $color-primary;
    
    :hover {
      color: $color-primary;
    }

    &.active {
      background-color: $body-background-color;
      color: #000000;
    }
  }

  .sub-menu {
    .sub-menu-item {
      // color: #FFFFFF;
      border-top: 1px solid $color-secondary;
      // background-color: $color-primary;

      :hover {
        background-color: #FFFFFF;
      }

      &.active {
        background-color: $body-background-color;
        color: #000000;
      }
    }
  }

  .menu-header{
    // color: #FFFFFF;
    // background-color: $color-primary;
    border-top: 1px solid rgb(255, 255, 255, 0.3);
  }

}