@import "../../../assets/sass/_variables";

.nav-button {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 15px 15px;
	/*margin-top: 10px;*/
	height: 150px;
	width: 150px;
/*	border-radius: 10%;*/
	border: 2px solid #5bc0de;
/*	background-color: #fafafa;*/
	color: #000000;
	/*font-weight: bold;*/

  @media (max-width: 599px) {
    height: 80px;
    width: 80px;
    margin: 5px 5px;
  }

  .icon {
    width: 100%;
    // background-color: blue;
    flex: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    color: $color-secondary;

    @media (max-width: 599px) {
      font-size: 1.5rem;
    }
  }

  .title {
    // width: 100%;
    // background-color: green;
    flex: 0.5;
    display: flex;
    justify-content: center;
    font-size: 1.1rem;
    // color: $color-primary;

    @media (max-width: 599px) {
      font-size: 0.8rem;
    }
  }

}