@import "../../../assets/sass/_variables";

.body {
  background-color: $body-background-color;
  height: $app-body-height;
  width: 100%;
  position: fixed;
  top: $app-header-height;
  overflow: scroll;

  @media (max-width: 599px) {
    height: $app-body-xs-height;
    top: $app-header-xs-height;
  }

  @media print {
    top: 0;
    min-height: 100%;
    position: static;
  }
}