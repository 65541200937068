@mixin generate-grid($columns, $size: "") {
  @for $i from 1 through $columns {
    $width: calc(100% / (#{$columns} / #{$i}));
    @if ($size == "") {
      .col-#{$i}-#{$columns} {
        width: $width;
      }
      .col-offset-#{$i}-#{$columns} {
        margin-left: $width;
      }
    } @else {
      .col-#{$size}-#{$i}-#{$columns} {
        width: $width;
      }
      .col-#{$size}-offset-#{$i}-#{$columns} {
        margin-left: $width;
      }
    }
  }
}

@mixin generate-padding-classes() {
  $increment: 8px;
  @for $i from 0 through 6 {
    $size: $increment * $i;

    .pa-#{$i} {
      padding: #{$size};
    }
    .px-#{$i} {
      padding-left: #{$size};
      padding-right: #{$size};
    }
    .py-#{$i} {
      padding-top: #{$size};
      padding-bottom: #{$size};
    }
    .pt-#{$i} {
      padding-top: #{$size};
    }
    .pr-#{$i} {
      padding-right: #{$size};
    }
    .pb-#{$i} {
      padding-bottom: #{$size};
    }
    .pl-#{$i} {
      padding-left: #{$size};
    }
  }
}

@mixin generate-margin-classes() {
  $increment: 8px;
  @for $i from 0 through 6 {
    $size: $increment * $i;
    .ma-#{$i} {
      margin: #{$size};
    }
    .mx-#{$i} {
      margin-left: #{$size};
      margin-right: #{$size};
    }
    .my-#{$i} {
      margin-top: #{$size};
      margin-bottom: #{$size};
    }
    .mt-#{$i} {
      margin-top: #{$size};
    }
    .mr-#{$i} {
      margin-right: #{$size};
    }
    .mb-#{$i} {
      margin-bottom: #{$size};
    }
    .ml-#{$i} {
      margin-left: #{$size};
    }
  }
}
