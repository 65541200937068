@import "../../../assets/sass/_variables";

.home-contents {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.home-menu {
	display: flex;
	/*flex-direction: row;*/
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	/*background-color: #dddddd;*/
	// width: 60%;
	width: 1110px;
	padding: 3rem;

	@media (max-width: 599px) {
		padding: 20px 10px;
	}
}

.home-menu a{
	text-decoration: none;
}