// Variables
@import "variables";
@import "mixins";

/*=================================================*/

// ----------------- FONTS ---------------------
@font-face {
  font-family: "Custom";
  src: url("../fonts/Custom-Regular.ttf");
}

@font-face {
  font-family: "Custom";
  src: url("../fonts/Custom-Italic.ttf");
  font-style: italic;
}

@font-face {
  font-family: "Custom";
  src: url("../fonts/Custom-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/MaterialIcons-Regular.ttf");
}

@font-face {
  font-family: "Material Icons Outlined";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/MaterialIconsOutlined-Regular.otf");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  vertical-align: middle;
  direction: ltr;
  -moz-font-feature-settings: "liga";
  -moz-osx-font-smoothing: grayscale;

  &.outlined {
    font-family: "Material Icons Outlined";
  }
}

// ----------------------------------------------

// --------------- Reset Defaults ---------------
html,
body {
  background-color: $body-background-color;
  color: #000;
  font-family: "Custom", sans-serif;
  font-size: $font-size-base;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  counter-reset: page;
  // counter-set: page 0;
}

* {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
  //scrollbar-width: thin;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background-color: inherit;
}

*::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: $border-color;
}

.dark::-webkit-scrollbar-thumb,
.dark *::-webkit-scrollbar-thumb {
  background: $border-color--dark-theme;
}

*::selection {
  background-color: $color-primary;
  color: #fff;
}

*:disabled {
  cursor: not-allowed !important;
  opacity: 0.9;
}

.disabled {
  cursor: not-allowed !important;
  opacity: 0.4 !important;
}

a {
  text-decoration: none;
  -webkit-transition: color 0.2s ease-in;
  -moz-transition: color 0.2s ease-in;
  transition: color 0.2s ease-in;
}

a:link,
a:visited {
  color: $color-secondary-500;
}

a:hover,
a:active {
  color: $color-primary;
}

input,
textarea {
  height: 32px;
  outline: none;
  font-family: "Custom", sans-serif;
  font-size: $font-size-base;
  // background-color: transparent;
}

textarea {
  overflow: hidden;
  min-height: 32px;
}

textarea[rows] {
  height: auto;
}

.input-file {
  height: 32px;
  line-height: 32px;
  font-size: $font-size-base;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;

  input[type="file"] {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    opacity: 0;
    position: absolute;
    overflow: hidden;
    width: 100%;
    line-height: inherit;
  }
}

input:not([type="checkbox"]):not([type="radio"]),
textarea {
  min-width: 32px;
}

ul,
ol {
  list-style-position: inside;
}

canvas {
  width: 100%;
}

// ----------------- components ------------------
.btn {
  background-color: $border-color;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  display: inline-flex;
  flex-flow: row nowrap;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 0 16px;
  height: 32px;
  line-height: 1;
  color: #000;
  font-family: "Custom", sans-serif;
  cursor: pointer;

  &.with-icon {
    gap: 0;

    .material-icons {
      margin: 0;
    }
  }

  &.elevated {
    box-shadow: 0 2px 3px rgba(204, 204, 204, 0.38);
  }

  &.primary {
    background-color: $color-primary;
    border: 1px solid $color-primary;
    color: #fff;
  }

  &.secondary {
    background-color: $color-secondary;
    border: 1px solid $color-secondary;
  }

  &.outlined {
    background-color: transparent;
    color: #000;
  }

  &.small {
    height: 28px;
    font-size: 12px;
    padding: 0 12px;
  }

  &.x-small {
    height: 20px;
    font-size: 11px;
    padding: 0 8px;
  }
  &.xx-small {
    height: 10px;
    font-size: 6px;
    padding: 0 4px;
  }

  &.large {
    height: 36px;
  }

  &.block {
    display: flex;
    width: 100%;
  }

  &.text {
    background-color: transparent;
    border: none;
  }

  &.icon {
    width: 32px;
    height: 32px;
    padding: 0;
    border-radius: 16px;

    &.small {
      height: 28px;
      width: 28px;
    }

    &.x-small {
      height: 20px;
      width: 20px;
    }
  }

  &:not(.text):focus {
    box-shadow: 0 1px 6px #ccc;
  }
}

.card {
  border: 1px solid $border-color;
  border-radius: $border-radius;
  // background-color: $body-background-color-700;
  background-color: #ffffff;

  &.inherit {
    background-color: $body-background-color-700;
  }

  &.transparent {
    background-color: rgba(240, 240, 240, 0.64);
  }
  &.transparent-fully {
    background-color: transparent;
  }

  &.elevated {
    border: none;
    box-shadow: 0 2px 3px $border-color, 0 -1px 0 $border-color;
  }

  &.border-0 {
    border-width: 0;
  }

  .card-header {
    padding: 12px 16px;
    border-radius: $border-radius $border-radius 0 0;
    font-size: 14px;
    border-bottom: 1px solid $border-color;
    // background-image: linear-gradient(
    //   to bottom,
    //   $body-background-color-300,
    //   $body-background-color-500
    // );

    &.small {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    &.no-gradient {
      background-image: none;
    }

    &.tile {
      border-radius: 0;
    }

    &.dense {
      padding-top: 8px;
      padding-bottom: 8px;
      background-color: #e4e4e4;
      background-image: none;
    }

    &.text-large {
      font-size: large;
    }

    &.with-actions {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: space-between;
    }

    .card-actions {
      display: flex;
      flex-flow: row wrap;
      align-items: center;

      > *:not(:last-child) {
        margin-right: 8px;
      }
    }
  }

  &.elevated .card-header {
    box-shadow: 0 -2px 4px inset #f5f5f5;
  }

  .card-body {
    padding: 16px;

    // :has(table) {
    //   padding-top: 32px;
    // }
  }

  .card-footer {
    padding: 16px;
    border-top: 1px solid $border-color;
    border-radius: 0 0 $border-radius $border-radius;
  }
}

.card-header {
  background-color: $body-background-color;
}

.accordion {
  > .accordion-item {
    > .accordion-item-title {
      font-size: 13px;

      > .accordion-item-toggle {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        background-color: $color-secondary;

        .material-icons {
          font-size: 14px;
        }
      }
    }

    .accordion-item-content {
      //margin-left: 28px;
      margin-top: 16px;
      margin-bottom: 16px;
    }

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}

.alert {
  border-width: 1px;
  border-style: solid;
  min-width: 256px;
  border-radius: $border-radius;
  margin: 0 auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  .message {
    word-break: break-all;
    flex-grow: 1;
  }

  .dismiss {
    cursor: pointer;
  }

  &.toast {
    padding: 8px;
    position: fixed;
    // top: 92px;
    top: $app-header-height;
    width: 40%;
    left: 50%;
    // margin-left: -20%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 9999;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.24);

    @media (max-width: 599px) {
      top: $app-header-xs-height;
      width: 80%;
    }
  }

  &.progress {
    color: $color-primary;
    padding: 8px 16px;

    .progress-bar-circle {
      border-top-color: $color-primary;
    }

    .message {
      flex-grow: 0;
    }
  }

  &.warning {
    border-color: #ffc600;
    background-color: #ffd84d;
    color: #000;

    &.dismissible .dismiss {
      color: #ffc600;
    }
  }

  &.error {
    border-color: #f0b6b4;
    background-color: #f7cdcb;
    color: #000;

    &.dismissible .dismiss {
      color: #ff9999;
    }
  }

  &.success {
    border-color: #9bfab1;
    background-color: #b6fcc6;
    color: #000;

    &.dismissible .dismiss {
      color: #36b555;
    }
  }

  &.dismissible .dismiss {
    .material-icons {
      font-size: 16px;
    }

    color: $color-primary;
    cursor: pointer;
  }

  &.broadcast {
    position: fixed;
    top: 0;
    left: 0;
    min-height: 48px;
    width: 100%;
    z-index: 9999;
    border-radius: 0;

    .message {
      text-align: center;
      font-size: 16px;
    }

    .dismiss {
      .material-icons {
        font-size: 20px;
      }
    }
  }

  > *:not(.progress-bar-circle) {
    padding: 8px 16px;
  }

  &:not(.toast) + *:not(.app-bar) {
    margin-top: 16px !important;
  }
}

.snackbar {
  padding: 20px;
  position: fixed;
  border-radius: $border-radius;
  bottom: 54px;
  right: 16px;
  z-index: 9999;
  max-width: 40%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.24);
  margin: 0 auto;
  display: flex;
  flex-flow: row nowrap;
  // color: #cb4154;
  color: white;
  align-items: center;
  opacity: 0.6;
  background-color: rgba(170, 170, 170, 0.095);
  font-weight: bold;
  text-shadow: 1px 1px 2px #cb4153;
  // backdrop-filter: blur(4px);
  cursor: pointer;

  .message {
    // word-break: break-all;
    flex-grow: 1;
    &.truncate {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .material-icons {
    font-size: x-large;
  }

  .dismiss {
    cursor: pointer;
  }

  &.info {
    color: $color-primary;
    padding: 8px 16px;
  }
  &.warning {
    border-color: #ffc600;
    background-color: #ffd84d;
    color: #000;
    &.dismissible .dismiss {
      color: #ffc600;
    }
  }
  &.error {
    border-color: #f0b6b4;
    background-color: #f7cdcb;
    color: #000;

    &.dismissible .dismiss {
      color: #ff9999;
    }
  }
}

.avatar {
  background-color: $color-primary-10;
  overflow: hidden;
  border-radius: 50%;
  width: 32px;
  height: 32px;

  img {
    width: 100%;
    min-height: 100%;
  }

  &.x-small {
    width: 26px;
    height: 26px;
  }

  &.small {
    width: 32px;
    height: 32px;
  }

  &.medium {
    width: 64px;
    height: 64px;
  }

  &.large {
    width: 128px;
    height: 128px;
  }

  &.x-large {
    width: 256px;
    height: 256px;
  }

  &.tile {
    border-radius: 0;
  }
}

.badge {
  display: inline-block;
  padding: 0 4px;
  background-color: #ff0000;
  // color: #fff;
  font-size: 11px;
  line-height: 16px;
  border-radius: 16px;
}

.chip {
  display: inline-block;
  background-color: $border-color;
  border: 1px solid $border-color;
  border-radius: 16px;
  padding: 4px 12px;

  &.primary {
    background-color: $color-primary;
    border-color: $color-primary;
    color: #fff;
  }

  &.warning {
    background-color: #ffc600;
    border-color: #ffc600;
    color: #fff;
  }

  &.secondary {
    background-color: $color-secondary;
    border-color: $color-secondary;
    color: #fff;
  }

  &.outlined {
    background-color: transparent;
  }

  &.small {
    font-size: 11px;
  }
}

.dropdown-container {
  display: inline-block;
  position: relative;
}

.dropdown-activator {
  display: inline-block;
}

.dropdown-container.active .dropdown-activator > * {
  background: $border-color !important;
  box-shadow: none !important;
}

.dropdown {
  position: absolute;
  min-width: 120px;
  background-color: #fff;
  border-radius: $border-radius;
  box-shadow: 0 2px 3px #ccc, 0 -1px 2px #ccc;
  z-index: 9999;

  .dropdown-menu {
    list-style: none;
    border-radius: inherit;
    overflow: hidden;

    li {
      display: block;
      padding: 12px 18px;
      cursor: pointer;
    }

    li:not(:last-child) {
      border-bottom: 1px solid $border-color;
    }

    li:hover {
      background-color: $body-background-color-500;
    }
  }
}

.divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  width: 100%;

  &.vertical {
    height: 1em;
    width: 0;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
  }

  &.dark {
    border-color: rgba(255, 255, 255, 0.4);
  }

  &.dashed {
    border-bottom-style: dashed;

    &.vertical {
      border-right-style: dashed;
    }
  }

  &.sunken {
    box-shadow: 0 1px 0 #fff;
  }
}

table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  font-size: 13px;

  caption {
    border: 1px solid $border-color;
    border-bottom-width: 0;
  }

  table {
    font-size: 12px;
  }

  td,
  th {
    padding: 8px;
    text-align: left;
    max-width: 300px;
    border-top: 1px solid $border-color;
    border-right: 1px solid $border-color;
    vertical-align: top;

    @media print {
      border-top: $table-print-border;
      border-right: $table-print-border;
    }

    &.small {
      min-width: 72px;
      max-width: 72px;
    }
    &.x-small {
      min-width: 56px;
      max-width: 56px;
    }
    &.md-sm {
      min-width: 64px;
      max-width: 64px;
    }

    &.medium {
      min-width: 128px;
      max-width: 128px;
    }

    &.large {
      min-width: 256px;
      max-width: 256px;
    }
  }

  th {
    font-family: "Custom", sans-serif;
  }

  td {
    transition: all 0.2s ease-out;

    &.clickable:hover {
      background-color: $color-secondary-transparent;
      color: $color-secondary-500;
      cursor: pointer;
    }
  }

  > thead {
    &.text-center th {
      text-align: center;

      &.vertical {
        writing-mode: sideways-lr;
        vertical-align: middle;
      }
    }

    > tr {
      background: linear-gradient(
        to bottom,
        $body-background-color-500,
        $color-primary-10
      );

      > td:first-child,
      > th:first-child {
        border-left: 1px solid $border-color;

        @media print {
          border-left: $table-print-border;
        }
      }
    }
  }

  > tbody {
    > tr {
      transition: all 0.2s ease-out;

      > td:first-child,
      > th:first-child {
        border-left: 1px solid $border-color;

        @media print {
          border-left: $table-print-border;
        }
      }

      &:last-child > td,
      &:last-child > th {
        border-bottom: 1px solid $border-color;

        @media print {
          border-bottom: $table-print-border;
        }
      }

      &:nth-child(even) {
        background: $body-background-color;
      }

      &:not(.disabled):not(.no-hover):hover {
        background: $color-primary-10;
      }
    }
  }

  > tfoot {
    > tr {
      background: linear-gradient(
        to bottom,
        $body-background-color-500,
        $color-primary-10
      );

      > td:first-child,
      > th:first-child {
        border-left: 1px solid $border-color;
        
        @media print {
          border-left: $table-print-border;
        }
      }

      &:first-child > td,
      &:first-child > th {
        border-top: none;
      }

      &:last-child > td,
      &:last-child > th {
        border-bottom: 1px solid $border-color;

        @media print {
          border-bottom: $table-print-border;
        }
      }
    }
  }

  tr.expanded {
    background-color: $body-background-color;
  }

  tr.disabled {
    opacity: 0.6;
  }

  &.row-clickable {
    tbody tr:hover {
      color: $color-secondary-500;
      cursor: pointer;
    }
  }

  &.no-outer-borders {
    td:first-child,
    th:first-child {
      border-left: none;
    }

    td:last-child,
    th:last-child {
      border-right: none;
    }

    > * > tr:first-child td,
    > * > tr:first-child th {
      border-top: none;
    }

    > tbody > tr:last-child td,
    > tfoot > tr:last-child td {
      border-bottom: none;
    }
  }

  .react-datepicker-popper {
    position: fixed !important;
  }

  .select__single-value {
    max-width: 8.25rem;
  }

  .select__menu {
    position: relative !important;
  }

  .dropdown {
    position: static !important;
  }
}

.no-table-striped {
  > tbody {
    > tr {
      &:nth-child(even) {
        background: none;
      }
    }
  }
}

.pagination {
  .items {
    .btn {
      background-color: transparent;
      padding: 0 10px;

      &.icon {
        padding: 0;
      }

      &.active {
        background-color: $color-secondary;
      }
    }
  }
}

.tab-layout {
  &.vertical {
    display: flex;
    flex-direction: row;
  }

  > .tab-layout-header {
    display: flex;
    flex-flow: row wrap;
    // padding: 0 8px;
    border-bottom: 1px solid $border-color;
  }

  &.outlined > .tab-layout-header {
    border-bottom: none;
  }

  &.vertical > .tab-layout-header {
    display: block;
    width: 250px;
    padding: 0;
    border-bottom: none;
  }

  > .tab-layout-header > .tab {
    border: 1px solid $border-color;
    border-bottom: none;
    background-color: $body-background-color;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    height: 36px;
    font-family: "Custom", sans-serif;
    line-height: 1;
    padding: 0 16px;
    cursor: pointer;

    &.red {
      background-color: #ff0000;
    }

    &.active {
      background-color: $color-primary;
      color: #fff;
    }
  }

  &.vertical > .tab-layout-header > .tab {
    border: 1px solid $border-color;
    border-bottom: none;
    margin-right: 0;
    width: 100%;
    text-align: left;

    &:last-child {
      border-bottom: 1px solid $border-color;
    }
  }

  &.outlined.vertical > .tab-layout-header > .tab {
    border-right: none;
  }

  &.vertical > .tab-layout-body {
    flex: 1;
  }

  &.outlined > .tab-layout-body {
    border: 1px solid $border-color;
  }
}

.descriptions {
  .item {
    flex-direction: column;
    border: 1px solid $border-color;

    &:not(:last-child) {
      border-right-width: 0;
    }

    .label {
      border-bottom: 1px solid $border-color;
      min-width: 128px;
    }

    .value {
      flex-grow: 1;
    }
  }

  &.horizontal {
    .item {
      flex-direction: row;

      .label {
        border-right: 1px solid $border-color;
        border-bottom: none;
      }
    }
  }

  &.large-label {
    .item .label {
      min-width: 176px;
    }
  }

  &.autofit-label {
    .item .label {
      min-width: auto;
    }
  }
}

.modal {
  position: absolute;
  // position: fixed;
  backdrop-filter: blur(2px);
  top: 0;
  left: 0;
  min-height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9998;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > .modal-content {
    width: 80%;
    min-width: 320px;
    // margin: 64px 0;
    margin: 10px 0;

    &.card {
      background-color: $body-background-color-500;
      &.transparent-fully {
        // background-color: transparent;
        .card-header {
          border-bottom: none;
          background-image: none;
          background-color: transparent;
        }
      }
    }
  }

  &.default > .modal-content {
    width: 80%;
  }

  &.x-small > .modal-content {
    width: 360px;
  }

  &.small > .modal-content {
    width: 40%;
  }

  &.medium > .modal-content {
    width: 60%;
  }

  &.large > .modal-content {
    width: 90%;
  }

  &.input-group {
    z-index: 99999;
  }
}

.pdf-viewer {
  display: block;
  width: 100%;
  height: 55vh;
}

.signature-canvas {
  width: 100%;
  height: 400px;
}

.signature {
  width: 100%;
  height: 64px;

  &.small {
    width: 96px;
    height: 48px;
  }

  img {
    width: auto;
    height: 100%;
  }
}

.tree {
  .tree-item {
    border-bottom: 1px solid #ccc;

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }

    .tree-item-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;
      padding: 16px;
    }

    .tree-item-header .tree-item-toggle {
      width: 20px;
      height: 20px;
      background-color: $color-primary;
      color: #fff;
      border-radius: $border-radius;
      cursor: pointer;
      display: none;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: #ccc;
      }
    }

    &.expandable > .tree-item-header {
      &:hover {
        background-color: $body-background-color-300;
      }

      > .tree-item-toggle {
        display: flex;
      }

      > .tree-item-title {
        font-weight: 700;
      }
    }

    .tree-item-content {
      margin-top: 16px;
      margin-bottom: 16px;
      padding-left: 52px;
    }

    .tree-item {
      margin-left: 36px;
      display: none;
    }

    &.expanded > .tree-item {
      display: block;
    }
  }
}

.quill {
  font-family: "Custom", sans-serif;

  .ql-toolbar {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  .ql-container {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  .ql-editor {
    font-family: "Custom", sans-serif;

    img {
      width: 200px;
    }
  }
}

.help {
  img {
    width: 100%;
  }
}

// -----------------------------------------------

// ---------------- Animations -------------------
.progress-bar-circle {
  width: 30px;
  height: 30px;
  border: 4px solid #fff;
  border-top-color: $color-primary;
  border-radius: 100%;
  animation: spin 1s infinite linear;
}

.button-progress-bar-circle {
  width: 15px;
  height: 15px;
  border: 2px solid #fff;
  border-top-color: $color-primary;
  border-radius: 100%;
  animation: spin 1s infinite linear;
}

.progress-bar-linear {
  height: 4px;
  padding: 0 !important;
  border: none !important;
  background-color: $color-primary-10;
  position: relative;
  overflow: hidden;

  .indicator {
    position: absolute;
    top: 0;
    height: inherit;
    background-color: $color-primary;
    animation: snake 1.75s infinite linear;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes stretch {
  0% {
    transform: scale(0.75);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes snake {
  0% {
    width: 0;
    left: 0;
  }
  50% {
    width: 50%;
    left: 25%;
  }
  100% {
    width: 0;
    left: 100%;
  }
}

// -----------------------------------------------

.pulse {
  animation-name: stretch;
  animation-duration: 0.8s;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-play-state: running;
}

.preloader {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .progress-bar-circle {
    width: 64px;
    height: 64px;
  }
}

#datepicker-portal,
#select-portal {
  position: absolute;
  z-index: 9999;
  top: 0;
}

// .select__wrapper {
//   position: absolute;
//   z-index: 99999999;
//   top: 0;
// }

// ------------------ Display --------------------

.overflow-hidden {
  overflow: hidden;
}

.visibility-hidden {
  visibility: hidden;
}

.hidden {
  display: none;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.d-inline-flex {
  display: inline-flex;
}

.d-flex {
  display: flex;
}

.flex-row {
  flex-flow: row wrap;
}

.flex-column {
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.align-baseline {
  align-items: baseline;
}

.align-end {
  align-items: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: end;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-space-evenly {
  justify-content: space-evenly;
}

.justify-space-around {
  justify-content: space-around;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.row {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
}

.row + .row:not([class*="mt-"]) {
  margin-top: 4px;
}

.row.no-gutters {
  margin: 0;
}

.row.no-gutters > .col,
.row.no-gutters > [class*="col-"] {
  padding: 0;
}

.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-2p4,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  width: 100%;
  padding: 4px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.row-3 .col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 600px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 960px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-2p4 {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

// -------------------- Forms --------------------
.input-group {
  &.block {
    width: 100%;
    input,
    textarea {
      width: 100%;
    }
  }

  .label {
    margin-bottom: 4px;
    font-size: 13px;
    // font-weight: bold;
  }

  .input {
    border: 1px solid $border-color;
    background-color: #fff;
    border-radius: $border-radius;
    flex-wrap: nowrap;
    position: relative;

    > *:not(.material-icons):not(textarea):not(.react-datepicker__tab-loop) {
      padding: 0 8px;
    }

    .material-icons {
      width: 32px;
      text-align: center;
    }

    .select__input-container {
      margin: 0;
      padding: 0;
    }

    .select__indicators .material-icons {
      width: auto;
    }

    .react-datepicker__aria-live {
      display: none !important;
    }

    .input-file,
    .react-datepicker-wrapper {
      flex-grow: 1;

      .react-datepicker__input-container {
        width: 100%;

        input {
          width: 100%;
        }
      }
    }

    textarea {
      width: 100%;
      padding: 8px;
      min-height: 32px;
    }
  }

  .error,
  .hint {
    margin-top: 4px;
  }

  &.is-focused {
    .input {
      border-color: $color-secondary;
    }
  }

  &.has-error {
    .input {
      border-color: #ff0000;
      box-shadow: 0 0 2px #ff0000;
    }
  }
  &.has-success {
    .input {
      border-color: #36b555;
      box-shadow: 0 0 2px #36b555;
    }
  }

  &.horizontal {
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 8px;

    .label {
      margin-bottom: 0;
      padding: 0;
      white-space: nowrap;
    }

    .input {
      flex-grow: 1;
    }

    .error,
    .hint {
      flex: 0 0 100%;
    }

    &.block {
      display: flex;
    }
  }
}

.input-field {
  background-color: #f3f3f3;
}

.checkbox {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5em;

  + .checkbox:not([class*="mt-"]) {
    margin-top: 8px;
  }

  &.block {
    display: flex;
  }

  input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    border: 2px solid $border-color;
    border-radius: 3px;
    display: grid;
    place-content: center;
  }

  input[type="checkbox"]::before {
    content: "";
    width: 12px;
    height: 12px;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    background-color: $color-secondary;
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
  }

  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }

  input[type="checkbox"]:focus {
    border: 2px solid $color-secondary;
  }
}

.radio-button {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5em;

  + .radio-button:not([class*="mt-"]) {
    margin-top: 8px;
  }

  &.block {
    display: flex;
  }

  &.reverse {
    flex-direction: row-reverse;
  }

  input[type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    border: 2px solid $border-color;
    border-radius: 9px;
    display: grid;
    place-content: center;
  }

  input[type="radio"]:checked::before {
    content: "";
    width: 12px;
    height: 12px;
    background-color: $color-secondary;
    border-radius: 6px;
  }

  input[type="radio"]:checked {
    border: 2px solid $color-secondary;
  }
}

.checkbox-group {
  display: flex;
  align-items: center;
  gap: 8px;

  .label {
    // font-weight: bold;
  }

  .checkbox {
    margin: 0 !important;
  }
}

.radio-button-group {
  display: flex;
  align-items: center;
  gap: 8px;

  .label {
    // font-weight: bold;
  }

  .radio-button {
    margin: 0 !important;
  }
}

// -----------------------------------------------

// ----------------- Typography ------------------
.text-small {
  font-size: 12px;
}

.text-xsmall {
  font-size: 10px;
}

.text-normal {
  font-size: $font-size-base;
}

.text-medium {
  font-size: medium;
}

.text-large {
  font-size: large;
}

.text-bold {
  font-weight: bold;
}

.text-italic {
  font-style: italic;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-title {
  font-size: 26px;
}

.word-wrap-break {
  word-wrap: break-word;
}

.primary-text {
  color: $color-primary;
}

.secondary-text {
  color: $color-secondary;
}

.tertiary-text {
  color: $color-tertiary;
}

.tertiary-light-text {
  color: $color-tertiary-50;
}

.grey-text {
  color: #808080;
}

.black-op6-text {
  color: rgb(0, 0, 0, 0.6);
}

.white-text {
  color: #fff;
}

.black-text {
  color: #000;
}

.hint-text {
  color: rgba(0, 0, 0, 0.38);

  &.dark {
    color: rgba(255, 255, 255, 0.6);
  }
}

.red-text {
  color: #ff0000;
}

.success-text {
  color: #28a745 !important;
}

a.success-text:hover, a.text-success:focus {
  color: #19692c !important;
}

.info-text {
  color: #17a2b8 !important;
}

a.info-text:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.warning-text {
  color: #ffc107 !important;
}

a.warning-text:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.danger-text {
  // color: #dc3545 !important;
  color: #dc3545;
}

a.danger-text:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.green-text {
  color: #36b555;
}

.blue-text {
  color: #006699;
}

.light-blue-text {
  color: #4fb4d7;
}

.purple-text {
  color: #5c2699;
}

.pink-text {
  color: #e36690;
}

.orange-text {
  color: #ffa500;
}

.lime-text {
  color: #a5c204;
}

.dark-yellow-text {
  color: #cccc00;
}

.caribbean-green-text {
  color: #00cc99;
}

.brick-red-text {
  color: #cb4154;
}

.serosa-text {
  color: #b17161;
}

.alba-text {
  color: #fae7b9;
}

.text-6 {
  color: #666;
}

.word-break {
  word-break: break-all;
}

// -----------------------------------------------

// ------------------- Spacing -------------------
@include generate-padding-classes();
@include generate-margin-classes();

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

// -----------------------------------------------

// --------------- Miscellaneous -----------------
.white-space-nowrap {
  white-space: nowrap;
}

.rounded {
  border-radius: $border-radius !important;
}

.rounded-top {
  border-radius: $border-radius $border-radius 0 0 !important;
}

.rounded-bottom {
  border-radius: 0 0 $border-radius $border-radius !important;
}

.border-0 {
  border-width: 0 !important;
}

.border-left {
  border-left: 1px solid $border-color;
}

.border-top {
  border-top: 1px solid $border-color;
}

.border-right {
  border-right: 1px solid $border-color;
}

.border-bottom {
  border-bottom: 1px solid $border-color;
}

.border {
  border: 1px solid $border-color;
}

.thick-left-border {
  border-left: 4px solid $color-secondary;
}

.thick-top-border {
  border-top: 4px solid $color-secondary;
}

.thick-right-border {
  border-right: 4px solid $color-secondary;
}

.thick-bottom-border {
  border-bottom: 4px solid $color-secondary;
}

.border-primary {
  border-color: $color-primary !important;
}

.border-primary-700 {
  border-color: $color-primary-700 !important;
}

.border-secondary {
  border-color: $color-secondary !important;
}

.border-secondary-500 {
  border-color: $color-secondary-500 !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.tile {
  border-radius: 0;
}

.bg-danger {
  background-color: #dc3545;
  border-color: #dc0202;
  color: #ffffff;
}

.bg-info {
  background-color: #e6f3f5;
  border-color: #cce2e6;
}

.bg-success {
  background-color: #28a745;
  border-color: #36b555;
  color: #fff;
}

.bg-warning {
  background-color: #ffc107;
  border-color: #ffc600;
  color: #000;
}

.bg-primary {
  background-color: $color-primary;
  border-color: $color-primary;
  color: #FFFFFF;

  &.border {
    background-color: transparent;
  }
}

.bg-primary-700 {
  background-color: $color-primary-700;
  border-color: $color-primary-700;
  color: #FFFFFF;

  &.border {
    background-color: transparent;
  }
}

.bg-secondary {
  background-color: $color-secondary;
  border-color: $color-secondary;
  color: #FFFFFF;

  &.border {
    background-color: transparent;
  }
}

.bg-secondary-500 {
  background-color: $color-secondary-500;
  border-color: $color-secondary-500;
  color: #FFFFFF;

  &.border {
    background-color: transparent;
  }
}

.bg-secondary-light {
  background-color: $color-secondary-300;
  border-color: $color-secondary-300;

  &.border {
    background-color: transparent;
  }
}

.bg-table-header {
  background: linear-gradient(
        to bottom,
        $body-background-color-500,
        $color-primary-10
  );
}

.tertiary {
  background-color: #f5d442;
  border-color: #f5d442;

  &.border {
    background-color: transparent;
  }
}

.tertiary-light {
  background-color: #faf7cd;
  border-color: #faf7cd;

  &.border {
    background-color: transparent;
  }
}

.black {
  background-color: #333;
  border-color: #333;

  &.border {
    background-color: transparent;
  }

  &.total * {
    background-color: #333 !important;
  }
}

.transparent {
  background-color: transparent;
}

.red {
  background-color: #ff0000;
  border-color: #ff0000;

  &.border {
    background-color: transparent;
  }
}

.green {
  background-color: #36b555;
  border-color: #36b555;

  &.border {
    background-color: transparent;
  }
}

.green-10 {
  background-color: #e8fcee;
  border-color: #e8fcee;

  &.border {
    background-color: transparent;
  }
}

.blue {
  background-color: #006699;
  border-color: #006699;

  &.border {
    background-color: transparent;
  }
}

.blue-10 {
  background-color: #e8effc;
  border-color: #e8fcee;

  &.border {
    background-color: transparent;
  }
}

.light-blue {
  background-color: #4fb4d7;
  border-color: #4fb4d7;

  &.border {
    background-color: transparent;
  }
}

.purple {
  background-color: #5c2699;
  border-color: #5c2699;

  &.border {
    background-color: transparent;
  }
}

.pink {
  background-color: #e36690;
  border-color: #e36690;

  &.border {
    background-color: transparent;
  }
}

.pink-100 {
  background-color: #fadce6;
  border-color: #fadce6;

  &.border {
    background-color: transparent;
  }
}

.pink-50 {
  background-color: #fcebf1;
  border-color: #fcebf1;

  &.border {
    background-color: transparent;
  }
}

.orange {
  background-color: #ffa500;
  border-color: #ffa500;

  &.border {
    background-color: transparent;
  }
}

.lime {
  background-color: #a5c204;
  border-color: #a5c204;

  &.border {
    background-color: transparent;
  }
}

.dark-yellow {
  background-color: #cccc00;
  border-color: #cccc00;

  &.border {
    background-color: transparent;
  }
}

.yellow {
  background-color: #cccc00;
  border-color: #cccc00;

  &.border {
    background-color: transparent;
  }
}

.caribbean-green {
  background-color: #00cc99;
  border-color: #00cc99;

  &.border {
    background-color: transparent;
  }
}

.brick-red {
  background-color: #cb4154;
  border-color: #cb4154;

  &.border {
    background-color: transparent;
  }
}

.bg-grey {
  background-color: #aaa;
  border-color: #aaa;
  color: #eee;

  &.border {
    background-color: transparent;
  }
}

.bg-light-grey {
  background-color: #e4e4e4;
  border-color: #e4e4e4;

  &.border {
    background-color: transparent;
  }
}

.serosa {
  background-color: #b17161;
  border-color: #b17161;

  &.is-focused {
    border-color: $color-secondary;
  }
}

.alba {
  background-color: #fae7b9;
  border-color: #fae7b9;

  &.is-focused {
    border-color: $color-secondary;
  }
}

.rubra {
  background-color: #cb4154;
  border-color: #cb4154;

  &.is-focused {
    border-color: $color-secondary;
  }
}

.hover-secondary-500:hover {
  background-color: $color-secondary-500 !important;
  color: #FFFFFF  !important;
  cursor: pointer;
}

.hover-primary-700:hover {
  background-color: $color-primary-700 !important;
  color: #FFFFFF  !important;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.border-color-base {
  border-color: $border-color;
}

.scrollable {
  &.x {
    overflow-x: auto;
  }

  &.y {
    // max-height: 55vh;
    max-height: calc(75vh);
    overflow-y: auto;

    &.fit-content-height {
      max-height: none;
      height: calc(
        ////100vh - 48px - 64px - 76px - 50px
        $app-body-height
      ); // 100vh minus app-bar, header, main padding and footer

      &.minus-descriptions {
        height: calc(100vh - 48px - 64px - 76px - 50px - 68px);
      }
    }
  }
}

.th-bg {
  background: linear-gradient(
    to bottom,
    $body-background-color-500,
    $color-primary-10
  );
}

.clickable-cell {
  cursor: pointer;
  &:hover {
    color: $color-secondary;
  }
}

// --------------------POSITION---------------------------

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: absolute;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.sticky {
  position: sticky;
  // z-index: 1;
}

.fixed {
  position: fixed;
}

.z-index-max {
  z-index: 999;
}

// ------------------ CUSTOM HEIGHT ----------------

.height-10 {
  height: 100px;
}
.height-20 {
  height: 200px;
}

.height-25 {
  height: 250px;
}
.height-28 {
  height: 280px;
}

.height-30 {
  height: 300px;
}

.max-height-30 {
  max-height: 300px;
}

.height-40 {
  height: 400px;
}
.height-50 {
  height: 500px;
}

.height-60 {
  height: 600px;
}

.line-height-normal {
  line-height: normal;
}

.line-height-medium {
  line-height: 1.5;
}

.line-height-large {
  line-height: 2;
}

// -------------------------------------------------

// -------------------- Tooltip --------------------

.tooltip-container {
  position: relative;
  // width: fit-content;
  display: inline-block;
  .tooltip {
    visibility: hidden;
    color: #fff;
    border-radius: $border-radius;
    position: absolute;
    z-index: 9999;
    top: 100%;
    left: 50%;
    margin-top: 5px;
    // margin-left: -60px;
    // width: auto;
    opacity: 0;
    // transition: opacity 1s ease-in;
    transition: none;
    &.fill-space {
      width: 100%;
      min-width: 320px;
      left: 0;
    }

    // transition-delay: 0.7s;
    * {
      background-color: $color-primary;
      transition: display 1s ease-in;

      // transition-delay: 0.7s;
    }
  }
  &:hover .tooltip {
    visibility: visible;
    opacity: 1;
    transition: display 1s ease-in;
  }
}

.report {
  .report-header {
    display: none;

    img {
      display: block;
      width: 100%;
    }

    .divider {
      border-top: 1px solid #333;
      border-bottom: 1px solid #333;
    }
  }

  .report-footer {
    display: none;
  }
}

// @page {
//   counter-increment: page;
//   counter-reset: page 1;
// }

@media print {
  // .Footer {
  //     position: fixed;
  //     bottom: 0;
  //     margin-top: 20px;
  // }

  // .Header {
  //     position: fixed;
  //     top: 0;
  //     left: 0;
  //     right: 0;
  //     width: 100%;
  //     z-index: 9999;
  //     margin-bottom: 100px;
  //     padding-top: 130;
  // }

  // table {
  //     page-break-inside: avoid;
  //     // margin-top: 100px;
  // }

  // thead {
  //     position: relative;
  //     width: 100%;
  //     background-color: red;
  //     margin-top: 30px;
  // }

  tr {
      page-break-inside: avoid;
  }
}

// .print {
//   display:table
// }

// #pageFooter {
//   display:table-footer-group;
// }

// table { -fs-page-paginate: paginate; }

.pageFooter {
  // counter-reset: page;
  counter-increment: page;
}

@media print {
  @page {
    //size: A4;
    margin: 0;
    margin-bottom: 15px;
    // @top-right-corner {
    //   content: "Page " counter(page);
    // }
    // @bottom-left {
    //   content: 'Page ' counter(page) + ' of ' + counter(pages);
    // }
  }
}

.pageFooter:after {
  content:"Page " counter(page);
  // left: 0; 
  // top: 100%;
  // white-space: nowrap; 
  // z-index: 20;
  // -moz-border-radius: 5px; 
  // border-radius: 5px; 
  // -moz-box-shadow: 0px 0px 4px #222;  
  // box-shadow: 0px 0px 4px;
  // background-image: -moz-linear-gradient(top, #eeeeee, #cccccc);  
}

@media print {
  @page {
    // size: A4;
    margin: 0;
    // margin-bottom: 15px;
  }

  body {
    margin: 0;
    padding: 0;
    background-color: #fff !important;
  }

  body * {
    visibility: hidden !important;
  }

  .report {
    background-color: #fff;

    .report-header {
      display: block;
    }

    .report-footer {
      display: flex;
      width: 100%;
      // padding-right: 10px;
      // position: fixed;
      bottom: 20px;
      right: 0px;
      left: 0px;
      padding: 0 30px;
    }
  }

  .print {
    position: absolute !important;
    left: 0;
    top: 0;
    padding: 25pt;
    width: 100%;

    tfoot {
      .sticky {
        position: relative;
      }
      .bottom-0 {
        bottom: unset;
      }
    }
    button {
      display: none;
    }
    .input {
      border: 1px solid #fff !important;
    }
  }

  .print,
  .print * {
    visibility: visible !important;
    overflow: visible !important;
    max-height: auto !important;
  }

  .print .hide-on-print {
    visibility: hidden !important;
  }

  .modal {
    display: block !important;

    .modal-content {
      width: 100% !important;
      margin: 0 !important;
    }
  }

  .page-break {
    page-break-after: always;
  }
}

.printable {
  margin: 0;
  padding: 0;
  background-color: #fff !important;

  &.report {
    background-color: #fff;

    .report-header {
      display: block;
    }

    .report-footer {
      display: flex;
      width: 100%;
      // padding-right: 10px;
      // position: fixed;
      bottom: 20px;
      right: 0px;
      left: 0px;
      padding: 0 30px;
    }
  }

  &.print {
    // position: absolute !important;
    left: 0;
    top: 0;
    padding: 25pt;
    width: 100%;

    // tfoot {
    //   .sticky {
    //     // position: relative;
    //   }
    //   .bottom-0 {
    //     // bottom: unset;
    //   }
    // }
    button {
      display: none;
    }
    .input {
      border: 1px solid #fff !important;
    }
  }

  &.print,
  &.print * {
    visibility: visible !important;
    overflow: visible !important;
    max-height: auto !important;
  }

  &.print .hide-on-print {
    visibility: hidden !important;
  }

  &.modal {
    display: block !important;

    .modal-content {
      width: 100% !important;
      margin: 0 !important;
    }
  }

  &.page-break {
    page-break-after: always;
  }
}

.loader {
  height: 100%;
  position: absolute;
  width: 100%;
}

///// Page Loading Floral Spinner //////

.floral-spinner {
  color: white;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.floral-spinner div {
  transform-origin: 40px 40px;
  animation: floral-spinner 1.2s linear infinite;
}
.floral-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}
.floral-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.floral-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.floral-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.floral-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.floral-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.floral-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.floral-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.floral-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.floral-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.floral-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.floral-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.floral-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes floral-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

// Mf added styles

.container {
	height: 100vh;
}

.app-header {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 5%;
}

.app-logo {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 1%;

    img{
        width: 30%;
        height: 30%;
    }
}

.h1 { 
    display: block;
    font-size: 2em;
    margin-top: 0.67em;
    margin-bottom: 0.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
}
.h2 {
    display: block;
    font-size: 1.5em;
    margin-top: 0.83em;
    margin-bottom: 0.83em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
}
.h3 { 
    display: block;
    font-size: 1.17em;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
}
.h4 { 
    display: block;
    margin-top: 1.33em;
    margin-bottom: 1.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
}
.h5 { 
    display: block;
    font-size: .83em;
    margin-top: 1.67em;
    margin-bottom: 1.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
}
.h6 { 
    display: block;
    font-size: .67em;
    margin-top: 2.33em;
    margin-bottom: 2.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.w-100vh {
  width: 100vh;
}

.h-100 {
  height: 100%;
}

.h-100vh {
  height: 100vh;
}

.mh-100vh {
    min-height: 100vh;
}

.bg-white {
  background-color: #ffffff;
}

.bg-c {
  background-color: #cccccc;
}

.bg-d {
  background-color: #dddddd;
}

.bg-e {
  background-color: #eeeeee;
}

.bg-ef {
  background-color: #efefef;
}

.bg-fe {
  background-color: #fefefe;
}

.bg-navbar {
  background-color: #2c3e50;
    color: #ffffff;
}

.bg-d {
  background-color: #ddd;
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.summary-report-card
{
  font-size: 1.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  .icon{
    height: 45px;
    width: 45px;
    border-radius:50%;
    margin-top: -40px;
    display: flex;
    justify-content: center;

    .material-icons {
      font-size: 0.8em;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  
  .number
  {
     font-size: 0.7em;
  }
}


