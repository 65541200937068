@import "../../../assets/sass/_variables";

.app-header {
  height: $app-header-height;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9997;

  @media (max-width: 599px) {
    height: $app-header-xs-height;
  }

  .app-bar {
    // background-color: $color-primary;
    // min-height: 48px;
    width: 100%;
    height: 45.5%;
    padding: 0 2%;
    /*position: fixed;*/

    @media (max-width: 599px) {
      height: 60%;
      padding-top: 5px;
    }

    &.elevated {
      border-bottom: none;
      box-shadow: 0 2px 6px $border-color;
    }

  }

  .nav-bar {
    height: 55.5%;
    width: 100%;
     padding: 0 2%;
    // padding-right: 5%;
    // padding-left: 1%;
    // font-size: 0.85em;

    @media (max-width: 599px) {
      height: 40%;
    }
    
    &.elevated {
      border-bottom: none;
      box-shadow: 0 2px 6px $border-color;
    }
  }

}