$color-primary: rgb(24,39,53);
$color-primary-light: rgb(24,39,53, 0.5);
$color-primary-300: rgb(30,144,255);
$color-primary-700: rgb(3, 21, 64);
$color-primary-10: rgb(237, 241, 250);
// $color-secondary: rgb(119,221,119);
$color-secondary: rgb(23,162,184);
$color-secondary-300: rgb(194, 251, 255);
$color-secondary-transparent: rgb(0,128,0, 0.6);
$color-secondary-500: rgb(23, 197, 209);
$color-secondary-10: rgb(232, 248, 250);
$color-tertiary: #f5d442;
$color-tertiary-50: #faf7cd;

// 182735

$font-size-base: 13px;
// $body-background-color: #f9f9f9;
$body-background-color: #f0f0f0;
$body-background-color-300: #fefefe;
$body-background-color-500: #f5f5f5;
$body-background-color-700: #f0f0f0;
$border-radius: 4px;
$border-color: rgba(104, 104, 104, 0.24);
$border-color--dark-theme: rgba(255, 255, 255, 0.24);

$app-header-height: 11%;
$app-header-xs-height: 15%;
$app-body-height: calc(100% - (11% + 3%));
$app-body-xs-height: calc(100% - (15% + 3%));

$table-print-border: 1pt solid #666;