@import "../../../assets/sass/_variables";

 .page {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    
    .page-content {
      padding: 16px;
      // padding-top: 64px;
      flex: 1;
      overflow: scroll;
    }
  }